// npm
import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { Box, Container, Stack, useMediaQuery, useTheme } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import SVG from 'react-inlinesvg'

// root
import { pageContextProps } from '../../gatsby-node'

// components
import ExternalLink from '@atoms/links/ExternalLink'
import Layout from '@organisms/global/Layout'
import ContactDetails from '@molecules/contact/ContactDetails'
import TextContent from '@molecules/text/TextContent'
import EnquiryForm from '@organisms/forms/EnquiryForm'

interface Props {
  data: Queries.ContactPageQuery
  pageContext: PageProps & pageContextProps
}

interface ContactBoxProps {
  icon: React.ReactNode
  heading: string
  text: string | React.ReactNode
}

const ContactBox = ({ icon, heading, text }: ContactBoxProps) => {
  const theme = useTheme()
  return (
    <Box
      component="li"
      bgcolor="secondary.main"
      p={4}
      width="100%"
      sx={{ listStyle: 'none', m: 0, mb: { xs: 2, lg: 3 } }}
    >
      <Stack direction="row" alignItems="center">
        <Box width="70px" mr={4}>
          {icon}
        </Box>
        <Box width="auto" typography="smBody">
          <Box
            component="h2"
            typography="smBody"
            fontWeight={theme.typography.fontWeightRegular}
            color="white"
            mt={0}
            mb={1}
          >
            {heading}
          </Box>
          {text}
        </Box>
      </Stack>
    </Box>
  )
}

const ContactPage = ({ data, pageContext }: Props) => {
  const d = data.datoCmsContact
  const theme = useTheme()
  const breakpoint = 'lg'
  const breakpointUp = useMediaQuery(theme.breakpoints.up(breakpoint))

  return (
    <Layout
      activeNavSlug={pageContext.activeNavSlug}
      metadata={{
        seoTags: d.seoMetaTags,
      }}
      minimalFooter
    >
      <Container
        maxWidth={breakpointUp ? 'xl' : 'md'}
        sx={{ py: { xs: 12, xl: 28 } }}
      >
        <Grid container spacing={6}>
          <Grid xs={12} lg={6} xl={5}>
            <Box component="ul" p={0} m={0} width="100%">
              <ContactBox
                heading={d.addressHeading}
                icon={<SVG src={d.addressIcon.url} />}
                text={d.address}
              />
              <ContactBox
                heading={d.contactHeading}
                icon={<SVG src={d.contactIcon.url} />}
                text={<ContactDetails />}
              />
              <ContactBox
                heading={d.hoursHeading}
                icon={<SVG src={d.hoursIcon.url} />}
                text={d.workingHours}
              />
            </Box>
          </Grid>
          <Grid xs={12} lg={6} xl={6} sx={{ ml: 'auto' }}>
            <TextContent
              mb={5}
              smallHeading={d.contactFormSmallHeading}
              mainHeading={d.contactFormMainHeading}
              text={d.contactFormText}
            />
            <EnquiryForm />
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ContactPage {
    datoCmsContact {
      address
      addressHeading
      addressIcon {
        url
      }
      contactFormButtonLabel
      contactFormErrorMessage
      contactFormMainHeading
      contactFormSmallHeading
      contactFormSuccessMessage
      contactFormText
      contactHeading
      contactIcon {
        url
      }
      emailAddress
      hoursHeading
      hoursIcon {
        url
      }
      phone
      workingHours
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`

export default ContactPage
